var gtm = require('../gtm/gtmAddTo');

/**
 * adds or removes the class to turn the wishlist icon red
 * @param {JQuery} $element - the jquey element to update
 * @param {string} data - data returned from the server's ajax call
 */
function updateWishlistClass($element, data) {
    if (data.status && data.status === 'add') {
        $element.addClass('isInWishlist');

        $element.attr('aria-label', $element.data('removelabel'));
        $element.attr('aria-pressed', 'true');
    } else {
        $element.removeClass('isInWishlist');

        $element.attr('aria-label', $element.data('addlabel'));
        $element.attr('aria-pressed', 'false');
    }
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {string} pid - the product id to update the wishlist icon
 */
function displayMessageAndChangeIcon(data, pid) {
    $.spinner().stop();
    let status;
    if (data.success) {
        status = 'alert-success';
        //update PDP product
        updateWishlistClass($('.product-detail[data-pid="' + pid + '"]').find('.add-to-wish-list'), data);
        //update tile products
        var $elements = $('.product[data-pid="' + pid + '"]');
        $elements.each(function () {
            updateWishlistClass($(this).find('.wishlistTile'), data);
        });
    } else {
        status = 'alert-danger';
    }

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages').append(`<div class="${data.status}-to-wishlist-alert text-center ${status}">${data.msg}</div>`);

    setTimeout(() => {
        $('.add-to-wishlist-messages').remove();
    }, 5000);
}

module.exports = {
    addToWishlist() {
        $('body').on('click', '.wishlistTile', function (e) {
            e.preventDefault();
            const url = $(this).attr('data-href');
            const pid = $(this).closest('.product').data('pid');
            let optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id');
            let optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
            optionId = optionId || null;
            optionVal = optionVal || null;
            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url,
                type: 'post',
                dataType: 'json',
                data: {
                    pid,
                    optionId,
                    optionVal,
                },
                success(data) {
                    if (data.gtmWishlistProduct) {
                        gtm.gtmAddToWishlist(data.gtmWishlistProduct, data.currencyCode, data.priceValue);
                    }
                    if (data.pid) {
                        var currWishlistList = $('.js-wishlist-list').data('wishlist-list') || [];
                        if (!currWishlistList.includes(data.pid)) {
                            currWishlistList.push(data.pid);
                            $('.js-wishlist-list').data('wishlist-list', currWishlistList);
                        } else {
                            currWishlistList = currWishlistList.filter(item => item !== data.pid);
                            $('.js-wishlist-list').data('wishlist-list', currWishlistList);
                        }
                    }
                    displayMessageAndChangeIcon(data, pid);
                },
                error(err) {
                    displayMessageAndChangeIcon(err, pid);
                },
            });
        });
    },
};
