'use strict';

function gtmAddToCart(products, currency, value, eventSource) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'add_to_cart',
        event_source: eventSource,
        item_name_copy: products[products.length - 1].item_name,
        item_id_copy: products[products.length - 1].item_id,
        ecommerce: {
            value: value,
            currency: currency,
            items: products,
        },
    });
}

function gtmAddToWishlist(product, currency, value) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'add_to_wishlist',
        ecommerce: {
            value: value,
            currency: currency,
            items: [product],
        },
    });
}

module.exports = {
    gtmAddToCart: gtmAddToCart,
    gtmAddToWishlist: gtmAddToWishlist,
};
