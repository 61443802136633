var gtm = require('../gtm/gtmAddTo');

/**
 * Update DOM elements with Ajax results
 *
 * @param {number} quantity - quantity
 * @param {string} selector - DOM element to look up in the tile's qty element
 * @return {undefined}
 */
function updateTile(quantity, selector) {
    $(selector).empty().html(quantity);
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function updateQuantities() {
    const items = $('.cart-json').data('cart');
    if (items && items.itemsquantities && items.itemsquantities.length > 0) {
        items.itemsquantities.forEach((item) => {
            updateTile(item.qty, `.cc-itemquantity-${item.id} .cc-quantity`);
        });
    }
}

/**
 * Show the remove button if the quantity of a product in the cart is the same as the product minOrderQuantity
 *
 */
function showRemoveButton() {
    $('.itemquantity').each(function () {
        var minQuantity = parseInt($(this).parents('.cc-addToCart').data('min-quantity'), 10);
        var currQuantity = parseInt($(this).children('.cc-quantity').html(), 10);
        if (minQuantity === currQuantity) {
            $(this).prev().addClass('d-none');
            $(this).prev().prev().removeClass('d-none');
        }
    });
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    return $($el).closest('.cc-addToCart').attr('data-pid');
}

/**
 * Retrieves url to use when adding a product to the cart
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl($el) {
    return $($el).closest('.cc-addToCart').data('endpoint-add-to-cart');
}

/**
 * Retrieves url to use when adding a product to the cart
 * @param {jquery} $el - DOM container for a given change qty button
 * @return {string} - The provided URL to use when changing quantity
 */
function getUpdateQuantityUrl($el) {
    return $($el).closest('.cc-addToCart').data('endpoint-update-quantity');
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    const $html = $('<div>').append($.parseHTML(html));

    const body = $html.find('.choice-of-bonus-product');
    const footer = $html.find('.modal-footer').children();

    return { body, footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    let bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    const htmlString =
        `${
            '<!-- Modal -->' +
            '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">' +
            '<span class="enter-message sr-only" ></span>' +
            '<div class="modal-dialog choose-bonus-product-dialog" ' +
            'data-total-qty="'
        }${data.maxBonusItems}"` +
        `data-UUID="${data.uuid}"` +
        `data-pliUUID="${data.pliUUID}"` +
        `data-addToCartUrl="${data.addToCartUrl}"` +
        'data-pageStart="0"' +
        `data-pageSize="${data.pageSize}"` +
        `data-moreURL="${data.showProductsUrlRuleBased}"` +
        `data-bonusChoiceRuleBased="${data.bonusChoiceRuleBased}">` +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        `    <span class="">${data.labels.selectprods}</span>` +
        '    <button type="button" class="close pull-right" data-dismiss="modal">' +
        '        <span aria-hidden="true">&times;</span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success(response) {
            const parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        },
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 * @param {jquery} el - DOM container for a given add to cart button
 */
function handlePostCartAdd(response, el) {
    let $addToCart = el.closest('.cc-addToCart');
    $('.cc-minicartTotal').trigger('count:update', response);
    if (response && response.cart && response.cart.items) {
        const pid = el.closest('.cc-addToCart').attr('data-pid');
        const products = response.cart.items.filter((item) => item.id === pid);
        if (products && products.length > 0) {
            $addToCart.find('.cc-updateQuantity').removeClass('d-none');
            $addToCart.find('.cc-updateQuantity__btn--delete').removeClass('d-none');
            $addToCart.find('.cc-updateQuantity__btn--left:not(.cc-updateQuantity__btn--delete)').addClass('d-none');
            $addToCart.find('.cc-updateQuantity .cc-quantity').html(products[0].quantity || '1');
        }
    }

    // const messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if (!response.error) {
            if ($('.add-to-cart-messages').length === 0) {
                $('body').append('<div class="add-to-cart-messages"></div>');
            }

            $('.add-to-cart-messages').append(`<div class="alert alert-success add-to-basket-alert text-center" role="alert">${response.message}</div>`);

            setTimeout(() => {
                $('.add-to-basket-alert').remove();
            }, 5000);
        }
    }
}

function updateProductsAlreadyInCart(quantity, pid) {
    // console.log("Into updateProductsAlreadyInCart tile");
    // console.log("quantity: ", quantity);
    // console.log("pid: ", pid);
    $(`.cc-addToCart[data-pid=${pid}]`).data('productsalreadyincart', quantity);
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the quantity change button
 * @param {jquery} el - DOM container for a given quantity change button
 */
function updateQuantity(response, el) {
    $('.cc-minicartTotal').trigger('count:update', response.resources);
    if (response && response.items && response.items) {
        let product;
        let $addToCart = el.closest('.cc-addToCart');
        const pid = $addToCart.attr('data-pid');
        const products = response.items.filter((item) => item.id === pid && !item.isBonusProductLineItem);
        if (products && products.length > 0) {
            product = products.pop();
            const minQuantity = product.quantityOptions ? product.quantityOptions.minOrderQuantity : 1;
            if (product.quantity === minQuantity) {
                $addToCart.find('.cc-updateQuantity__btn--delete').removeClass('d-none');
                $addToCart.find('.cc-updateQuantity__btn--left:not(.cc-updateQuantity__btn--delete)').addClass('d-none');
            } else {
                $addToCart.find('.cc-updateQuantity__btn--left:not(.cc-updateQuantity__btn--delete)').removeClass('d-none');
                $addToCart.find('.cc-updateQuantity__btn--delete').addClass('d-none');
            }
            $addToCart.find('.cc-quantity').html(product.quantity);
            updateProductsAlreadyInCart(parseInt(product.quantity), pid);
        }
    }
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url,
            method: 'GET',
            success() {
                // reporting urls hit on the server
            },
            error() {
                // no reporting urls hit on the server
            },
        });
    }
}

function disablePlusBtn(pid) {
    const addToCartSel = `.cc-addToCart[data-pid=${pid}]`;
    $(addToCartSel).find('.cc-updateQuantity__btn--right').attr('disabled', true);
}

function enablePlusBtn(pid) {
    const addToCartSel = `.cc-addToCart[data-pid=${pid}]`;
    $(addToCartSel).find('.cc-updateQuantity__btn--right').removeAttr('disabled');
}

function disableMinusBtn(pid) {
    const addToCartSel = `.cc-addToCart[data-pid=${pid}]`;
    $(addToCartSel).find('.cc-updateQuantity__btn--left').attr('disabled', true);
}

function enableMinusBtn(pid) {
    const addToCartSel = `.cc-addToCart[data-pid=${pid}]`;
    $(addToCartSel).find('.cc-updateQuantity__btn--left').removeAttr('disabled');
}

function isMaxReached(pid, showMessage) {
    const addToCartSel = '.cc-addToCart[data-pid=' + pid + ']';
    const productsAlreadyInCart = $(addToCartSel).data('productsalreadyincart');
    const maxOrderableQuantity = $(addToCartSel).data('maxorderablequantity');
    var result = false;

    if (maxOrderableQuantity !== '') {
        if (parseInt(productsAlreadyInCart) >= parseInt(maxOrderableQuantity)) {
            result = true;

            if (showMessage) {
                $('.cc-warning').addClass('cc-warning__detailPosition show');
                $('.cc-warning__alert').removeClass('d-none');
                $('.cc-warning__text').text('Quantità massima raggiunta.');

                setTimeout(function () {
                    $('.cc-warning__alert').addClass('d-none');
                    $('.cc-warning').removeClass('show');
                }, 3000);
            }
        }
    }
    return result;
}

function checkButtonsStatus(pid) {
    const addToCartSel = '.cc-addToCart[data-pid=' + pid + ']';
    const productsAlreadyInCart = $(addToCartSel).data('productsalreadyincart');
    const maxOrderableQuantity = $(addToCartSel).data('maxorderablequantity');

    // console.log("productsAlreadyInCart: ", productsAlreadyInCart);
    // console.log("maxOrderableQuantity: ", maxOrderableQuantity);

    enablePlusBtn(pid);
    enableMinusBtn(pid);

    if (maxOrderableQuantity !== '') {
        if (parseInt(productsAlreadyInCart) >= parseInt(maxOrderableQuantity)) {
            disablePlusBtn(pid);

            $('.cc-warning').addClass('cc-warning__detailPosition show');
            $('.cc-warning__alert').removeClass('d-none');
            $('.cc-warning__text').text('Quantità massima raggiunta.');

            setTimeout(function () {
                $('.cc-warning__alert').addClass('d-none');
                $('.cc-warning').removeClass('show');
            }, 3000);
        }
    }
}

function tilemaxOrderQuantityCheck(categoryWithLimitId, maxOrderQuantity, currentCartQuantity) {
    if (categoryWithLimitId && maxOrderQuantity && currentCartQuantity) {
        var tilesWithDissabledButtons = $('button:disabled').parents('.cc-productTile__body');
        for (let i = 0; i < tilesWithDissabledButtons.length; i++) {
            var tile = tilesWithDissabledButtons[i];
            var tileCategoryWithLimitId = tile.dataset.categorywithlimitid;
            var tileUnitValue = Number(tile.dataset.unitvalue);
            if (tileCategoryWithLimitId === categoryWithLimitId && currentCartQuantity + tileUnitValue <= maxOrderQuantity) {
                var pid = tile.dataset.productid;
                var isMax = isMaxReached(pid, false);
                if (!isMax) {
                    $("[data-productid='" + pid + "']")
                        .find('.cc-updateQuantity__btn--right')
                        .prop('disabled', false);
                }
            }
        }
    }
}

function applyUpdateQuantityCart(element) {
    const el = $(element);
    const decrease = $(element).data('decrease') ? $(element).data('decrease') : false;

    $('body').trigger('product:beforeAddToCart', element);

    const pid = getPidValue($(element));
    if (!decrease) {
        // check max product quantity reached
        var isMax = isMaxReached(pid, true);
        if (isMax) {
            return;
        }
    }

    const updateQuantityUrl = getUpdateQuantityUrl($(element));
    let $productContainer = $(element).closest('.product-detail');
    if (!$productContainer.length) {
        $productContainer = $(element).closest('.quick-view-dialog').find('.product-detail');
    }

    const form = {
        pid,
        decrease,
        quantity: 1,
    };

    $(element).trigger('updateAddToCartFormData', form);
    if (updateQuantityUrl) {
        $.ajax({
            url: updateQuantityUrl,
            method: 'GET',
            data: form,
            success(data) {
                // Default store navigation
                if (data.storeNotSelected) {
                    $('.cc-modal--delivery').modal('show');
                } else {
                    $('.cc-warning__alert').addClass('d-none');
                    $('.minicart').trigger('count:update', data);
                    $('body').trigger('product:afterAddToCart', data);
                    if (data && data.basket && data.basket.items) {
                        const id = el.closest('.cc-addToCart').attr('data-pid');
                        const products = data.basket.items.filter((item) => item.id === id && !item.isBonusProductLineItem);
                        if (products.length === 0) {
                            el.closest('.cc-addToCart').find('.cc-updateQuantity').addClass('d-none');
                            const addToCartBtn = el.closest('.cc-addToCart').find('.cc-tile-add-to-cart');
                            addToCartBtn.removeClass('invisible');
                            addToCartBtn.removeAttr('disabled');
                            $('.cc-minicartTotal').trigger('count:update', data.basket.resources);
                        }
                        updateProductsAlreadyInCart(1, pid);
                    } else if (data && data.items) {
                        updateQuantity(data, el);
                    }

                    checkButtonsStatus(pid);
                    miniCartReportingUrl(data.reportingURL);
                    // when decreasing a product with category maxOrderQuantity, check if there are products of the same category
                    // with the + button disabled and enable it if that product quantity can be added to the cart
                    // without surpassing the category maxOrderQuantity
                    if (decrease && data.maxOrderQuantity) {
                        var categoryWithLimitId = data.categoryWithLimitId ? data.categoryWithLimitId : null;
                        var maxOrderQuantity = data.maxOrderQuantity ? data.maxOrderQuantity : null;
                        var currentCartQuantity = data.currentCartQuantity ? data.currentCartQuantity : null;

                        tilemaxOrderQuantityCheck(categoryWithLimitId, maxOrderQuantity, currentCartQuantity);
                    }
                }

                $.spinner().stop();
            },
            error(err) {
                $('.cc-warning__alert').removeClass('d-none');
                $('.cc-warning__text').text(err.responseJSON.errorMessage);

                setTimeout(function () {
                    $('.cc-warning__alert').addClass('d-none');
                }, 5000);

                $.spinner().stop();
            },
        });
    }
}

module.exports = {
    addToCart() {
        $(document).on('click', '.cc-productTile button.cc-tile-add-to-cart', function () {
            const el = $(this);

            el.attr('disabled', 'disabled');

            $('body').trigger('product:beforeAddToCart', this);

            const pid = getPidValue($(this));
            const daysForShipping = $(this).closest('.cc-addToCart').data('daysforshipping');

            let $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            updateProductsAlreadyInCart(0, pid);
            const addToCartUrl = getAddToCartUrl(el);

            const form = {
                pid,
                daysForShipping,
                quantity: 0,
            };

            //Setting eventSource for addToCart GTM event
            var eventSource;
            if ($('.cc-editorial').length > 0) {
                eventSource = 'homepage';
            }
            if ($('.cc-search__results').length > 0) {
                eventSource = 'PLP';
            }
            if ($('.product-detail').length > 0) {
                eventSource = 'recommendations';
            }

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success(data) {
                        // Default store navigation
                        if (data.storeNotSelected) {
                            $('.cc-modal--delivery').modal('show');
                            el.removeAttr('disabled');
                        } else if (!data.productAvailabilityResponse) {
                            $('.cc-warning__alert').removeClass('d-none');
                            $('.cc-warning__text').text(data.availabilityMessage);

                            setTimeout(function () {
                                $('.cc-warning__alert').addClass('d-none');
                            }, 8000);
                            el.removeAttr('disabled');
                        } else {
                            if (data.error) {
                                $('.cc-warning__alert').removeClass('d-none');
                                $('.cc-warning__text').text(data.message);

                                setTimeout(function () {
                                    $('.cc-warning__alert').addClass('d-none');
                                }, 5000);
                                el.removeAttr('disabled');
                            } else {
                                el.closest('.cc-addToCart').find('.cc-tile-add-to-cart').addClass('invisible');
                                handlePostCartAdd(data, el);
                                gtm.gtmAddToCart(data.gtmCartProducts, data.currencyCode, data.totalGrossPrice, eventSource);
                                $('body').trigger('product:afterAddToCart', data);
                                miniCartReportingUrl(data.reportingURL);
                            }
                        }
                        $.spinner().stop();
                    },
                    error() {
                        $.spinner().stop();
                    },
                });
            }
        });
    },
    updateQuantityCart() {
        document.ondblclick = function (e) {
            e.preventDefault();
        };

        $(document).on('click', '.cc-productTile button.cc-updateQuantity__btn--left', function () {
            const pid = getPidValue($(this));
            disableMinusBtn(pid);
            applyUpdateQuantityCart(this);
        });

        $(document).on('click', '.cc-productTile button.cc-updateQuantity__btn--right', function () {
            const pid = getPidValue($(this));
            disablePlusBtn(pid);
            applyUpdateQuantityCart(this);
        });
    },
    updateMinicart() {
        $('body').on('cart:update', () => {
            updateQuantities();
        });
    },
    tileLoad() {
        updateQuantities();
        showRemoveButton();
    },
};
